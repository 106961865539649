import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  List,
  ListItem,
  Avatar,
  TextField,
  BottomNavigationAction,
  Paper,
  Grid,
  ListItemText,
  Box,
  Divider,
  Tabs,
  Tab,
  Badge,
  Chip,
  IconButton,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Home,
  Search,
  AddCircle,
  OndemandVideoOutlined,
  EmailOutlined,
  WalletOutlined,
  GroupOutlined,
  SpeakerGroup,
  Apps,
  SpeakerGroupOutlined,
  AppsOutlined,
  AddCircleOutline,
  SearchOutlined,
  HomeOutlined,
  Notifications,
  Label,
  Done,
  Cancel,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import DashboardHeader from './header';
import axios from 'axios';
import { toast } from 'react-toastify';

function LeftPanel({
  chatListData,
  contactsData,
  setSelectedChat,
  selectedChat,
  setPage,
  setMessages,
  tabValue,
  setTabValue,
  handleHeaderTabClick,
  fetchContacts,
  setShowLeft,
  enableSideDrawer,
}) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);
  const [requestsData, setRequestsData] = useState(null);
  const [requestCount, setRequestCount] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getSecondaryText = (chatRoom) => {
    const message = chatRoom?.chat?.message;
    // if (
    //   chatRoom?.chat?.message.includes(
    //     'https://soapboxapi.megahoot.net/storageChat'
    //   )
    // ) {
    //   return 'SHARED A MEDIA FILE';
    // }

    if (message.match(/\.(gif|png|jpg|jpeg|eps)$/i)) {
      return (
        <img
          src={message.replace('http://', 'https://')}
          alt="mchathive"
          style={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '50px',
            maxHeight: '50px',
            objectFit: 'contain',
            borderRadius: '8px',
          }}
          draggable={false}
        />
      );
    }
    if (message.match(/\.(mp3|wav)$/i)) {
      return (
        <audio
          src={message.replace('http://', 'https://')}
          controls={false}
          style={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '50px',
            maxHeight: '50px',
          }}
        />
      );
    }
    if (message.match(/\.(mp4|mov|wmv|webm|avi)$/i)) {
      return (
        <video
          src={message.replace('http://', 'https://')}
          controls={false}
          style={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '50px',
            maxHeight: '50px',
          }}
          draggable={false}
        />
      );
    }
    if (message.match(/\.(pdf|doc|docx|xls|xlsx|txt)$/i)) {
      return (
        <iframe
          style={{
            maxWidth: '50px',
            minWidth: '50px',
            width: 'fit-content',
            maxHeight: '40px',
          }}
          src={message}
          allowFullScreen={true}
        />
      );
    }

    return chatRoom?.chat?.message?.length > 80
      ? `${chatRoom?.chat?.message?.slice(0, 80)}...`
      : chatRoom?.chat?.message;
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchMessageRequests();
    fetchContacts(userInfo?.username);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const fetchMessageRequests = () => {
    axios
      .get(
        `https://soapboxapi.megahoot.net/upload/userRequests?userId=${userInfo?.username}`
      )
      .then((res) => {
        setRequestsData(res.data.requests);
        const activeReq = res.data.requests?.filter(
          (e) => e.status === 'pending'
        );
        setRequestCount(activeReq.length);
      });
  };

  const handleApproveRequest = (request) => {
    const messageRequest = async () => {
      await axios.post(
        `https://soapboxapi.megahoot.net/upload/approveMessageRequest`,
        {
          requestId: request.id,
        }
      );
      fetchMessageRequests();
    };

    const from =
      request?.from === userInfo?.username ? request.to : request.from;
    toast.promise(messageRequest, {
      pending: `Approving message request of ${from}`,
      success: `Message request of ${from} is approved successfully`,
      error: 'Please try again',
    });
  };

  const handleDeleteRequest = (request) => {
    const messageRequest = async () => {
      await axios.post(`https://soapboxapi.megahoot.net/upload/deleteRequest`, {
        requestId: request.id,
      });
      fetchMessageRequests();
    };

    const from =
      request?.from === userInfo?.username ? request.to : request.from;
    toast.promise(messageRequest, {
      pending: `Deleting message request of ${from}`,
      success: `Message request to ${from} is deleted successfully`,
      error: 'Please try again',
    });
  };

  useEffect(() => {
    fetchMessageRequests();
  }, [userInfo]);
  return (
    <Grid>
      <Card
        style={{
          height: isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 64px)',
          borderRadius: 0,
          backgroundColor: '#f5f5f5',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row', // Align items horizontally,
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column', // Align navigation items vertically
              alignItems: 'center',
              justifyContent: 'space-between',
              height: isMobile ? '50px' : '100%',
              padding: '16px 0',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <BottomNavigationAction
              label="Dashboard"
              showLabel={true}
              icon={<HomeOutlined />}
              onClick={() => {
                setTabValue(0);
                if (isMobile) {
                  setShowLeft(false);
                }
                setSelectedChat(null);
              }}
              selected={tabValue === 0}
            />
            <BottomNavigationAction
              label="Post"
              showLabel={true}
              icon={<AddCircle sx={{ fontSize: 40 }} color="primary" />}
              onClick={() => handleHeaderTabClick('CREATEPOST')}
              selected={tabValue === 2}
            />
            <BottomNavigationAction
              label="Discover"
              showLabel={true}
              icon={<SearchOutlined />}
              onClick={() => {
                setTabValue(1);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              selected={tabValue === 1}
            />

            <BottomNavigationAction
              label="Soapbox"
              showLabel={true}
              icon={<OndemandVideoOutlined />}
              onClick={() => {
                setTabValue(3);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              selected={tabValue === 3}
            />
            <BottomNavigationAction
              label="Mega Mail"
              showLabel={true}
              icon={<EmailOutlined />}
              onClick={() => handleHeaderTabClick('EMAIL')}
            />
            <BottomNavigationAction
              label="Wallet"
              showLabel={true}
              icon={<WalletOutlined />}
              onClick={() => handleHeaderTabClick('WALLET')}
            />
            <BottomNavigationAction
              label="Channels"
              showLabel={true}
              icon={<GroupOutlined />}
              onClick={() => handleHeaderTabClick('CHANNELS')}
            />
            <BottomNavigationAction
              label="Clubs"
              showLabel={true}
              icon={<SpeakerGroupOutlined />}
              onClick={() => handleHeaderTabClick('CHANNELS')}
            />
            <BottomNavigationAction
              label="Mini Apps"
              showLabel={true}
              icon={<AppsOutlined />}
              onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
            />
            <BottomNavigationAction
              label={`Notifications`}
              showLabel={true}
              icon={
                <Badge badgeContent={requestCount} color="error">
                  <Notifications />
                </Badge>
              }
              onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
            />
            <BottomNavigationAction
              label="Me"
              icon={
                <Avatar
                  src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    width: '30px',
                    height: '30px',
                  }}
                />
              }
              onClick={() => {
                setTabValue(4);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              selected={tabValue === 4}
            />
          </Box>
        )}

        <CardContent
          style={{
            paddingBottom: 16,
            flexGrow: 1, // This will make the list grow to fill the available space
            overflowY: 'auto', // Allows scrolling if the chat list is too long
          }}
        >
          {/* Search Box */}
          {!isMobile && (
            <TextField fullWidth placeholder="Search" size="small" />
          )}

          {/* Chat List */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Messages" {...a11yProps(0)} />
              <Tab label="Contacts" {...a11yProps(1)} />
              <Tab
                label={
                  <Badge badgeContent={requestCount} color="error">
                    Requests
                  </Badge>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          {value === 0 && (
            <List>
              {chatListData?.map((chatRoom) => (
                <ListItem
                  button
                  key={chatRoom.id}
                  onClick={() => {
                    setSelectedChat(chatRoom);
                    setMessages(null);
                    setPage(1);
                    setTabValue(0);
                    if (isMobile) {
                      setShowLeft(false);
                    }
                  }}
                  selected={selectedChat?.id === chatRoom.id}
                  style={{
                    borderRadius: 8,
                    marginBottom: 8,
                    padding: '10px 15px',
                    cursor: 'pointer',
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      marginRight: 12,
                    }}
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.profilePic}`}
                  >
                    {chatRoom.name?.slice(0, 1) ||
                      chatRoom.username?.slice(0, 1) ||
                      chatRoom.chatFrom?.slice(0, 1)}
                  </Avatar>
                  <ListItemText
                    primary={
                      chatRoom.name || chatRoom.username || chatRoom.chatFrom
                    }
                    secondary={getSecondaryText(chatRoom)}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
              ))}
            </List>
          )}

          {value === 2 && (
            <List>
              <Divider>New Requests</Divider>
              {requestsData &&
                requestsData
                  ?.filter((e) => e.status === 'pending')
                  .map((request, index) => (
                    <ListItem
                      key={index}
                      button
                      style={{
                        borderRadius: 8,
                        marginBottom: 8,
                        padding: '10px 15px',
                        cursor: 'pointer',
                      }}
                    >
                      {request?.from === userInfo?.username ? (
                        <Typography
                          sx={{ marginRight: '10px' }}
                        >{`Requested ${request?.to}`}</Typography>
                      ) : (
                        <Typography
                          sx={{ marginRight: '10px' }}
                        >{`${request?.from}'s Request`}</Typography>
                      )}

                      {/* <Chip
                        sx={{ ml: 1 }}
                        label={request?.status}
                        size="small"
                        variant="outlined"
                        color={
                          request.status === 'completed'
                            ? 'success'
                            : request.status === 'failed'
                            ? 'error'
                            : 'primary'
                        }
                      ></Chip> */}
                      {/* <IconButton sx={{ fontSize: '12px' }}>
                        Accept <Done />
                      </IconButton>
                      <IconButton sx={{ fontSize: '12px' }}>
                        Reject <Cancel />
                      </IconButton> */}
                      <Chip
                        sx={{ ml: 1 }}
                        label={
                          request?.from === userInfo?.username
                            ? 'sent'
                            : 'received'
                        }
                        size="small"
                        variant="outlined"
                        color={
                          request.status === 'completed'
                            ? 'success'
                            : request.status === 'failed'
                            ? 'error'
                            : 'primary'
                        }
                      ></Chip>
                      {request?.from === userInfo?.username ? (
                        <>
                          <Button
                            size="small"
                            sx={{ borderRadius: 5, mx: 0.5 }}
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteRequest(request)}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="small"
                            sx={{ borderRadius: 5, mx: 0.5 }}
                            variant="contained"
                            onClick={() => {
                              handleApproveRequest(request);
                            }}
                          >
                            Accept
                          </Button>
                          <Button
                            size="small"
                            sx={{ borderRadius: 5, mx: 0.5 }}
                            variant="outlined"
                            color="error"
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    </ListItem>
                  ))}
              <Divider>Past Requests</Divider>
              {requestsData &&
                requestsData
                  ?.filter((e) => e.status !== 'pending')
                  .map((request, index) => (
                    <ListItem
                      key={index}
                      button
                      style={{
                        borderRadius: 8,
                        marginBottom: 8,
                        padding: '10px 15px',
                        cursor: 'pointer',
                      }}
                    >
                      {request?.from === userInfo?.username
                        ? request.to
                        : request.from}
                      <Chip
                        sx={{ ml: 1 }}
                        label={request?.status}
                        size="small"
                        variant="outlined"
                        color={
                          request.status === 'completed'
                            ? 'success'
                            : request.status === 'failed'
                            ? 'error'
                            : 'primary'
                        }
                      ></Chip>
                      <Chip
                        sx={{ ml: 1 }}
                        label={
                          request?.from === userInfo?.username
                            ? 'sent'
                            : 'received'
                        }
                        size="small"
                        variant="outlined"
                        color={
                          request.status === 'completed'
                            ? 'success'
                            : request.status === 'failed'
                            ? 'error'
                            : 'primary'
                        }
                      ></Chip>
                    </ListItem>
                  ))}
            </List>
          )}

          {value === 1 && (
            <List>
              {contactsData?.map((chatRoom, key) => (
                <ListItem
                  button
                  key={key}
                  onClick={() => {
                    setSelectedChat({
                      chatFrom: chatRoom.username,
                      chatTo: userInfo.username,
                      createdAt: '',
                      fcmToken: '',
                      name: chatRoom.name,
                      profilePic: chatRoom.profilePic,
                      roomname: userInfo.username + chatRoom.username,
                      seen: 0,
                      shared_public_key: '',
                      threadId: '',
                      chat: null,
                    });
                    setMessages(null);
                    setPage(1);
                    setTabValue(0);
                    if (isMobile) {
                      setShowLeft(false);
                    }
                  }}
                  // selected={selectedChat?.id === chatRoom.id}
                  style={{
                    borderRadius: 8,
                    marginBottom: 8,
                    padding: '10px 15px',
                    cursor: 'pointer',
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      marginRight: 12,
                    }}
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.profilePic}`}
                  >
                    {chatRoom.name?.slice(0, 1) ||
                      chatRoom.username?.slice(0, 1) ||
                      chatRoom.chatFrom?.slice(0, 1)}
                  </Avatar>
                  <ListItemText
                    primary={
                      chatRoom.name || chatRoom.username || chatRoom.chatFrom
                    }
                    secondary={getSecondaryText(chatRoom)}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
        {isMobile && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column', // Align navigation items vertically
              alignItems: 'center',
              justifyContent: 'space-between',
              height: isMobile ? '80px' : '100%',
              padding: '16px 0',
              backgroundColor: theme.palette.background.paper,
              bottom: '64px',
              width: '100%',
            }}
          >
            <BottomNavigationAction
              label="Home"
              showLabel={true}
              icon={<HomeOutlined />}
              onClick={() => {
                setTabValue(0);
                if (isMobile) {
                  setShowLeft(false);
                }
                setSelectedChat(null);
              }}
              selected={tabValue === 0}
            />
            <BottomNavigationAction
              label="Post"
              showLabel={true}
              icon={<AddCircle sx={{ fontSize: 40 }} color="primary" />}
              onClick={() => handleHeaderTabClick('CREATEPOST')}
              selected={tabValue === 2}
            />
            <BottomNavigationAction
              label="Discover"
              showLabel={true}
              icon={<SearchOutlined />}
              onClick={() => {
                setTabValue(1);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              selected={tabValue === 1}
            />

            <BottomNavigationAction
              label="Soapbox"
              showLabel={true}
              icon={<OndemandVideoOutlined />}
              onClick={() => {
                setTabValue(3);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              selected={tabValue === 3}
            />
            {/* <BottomNavigationAction
              label="Mega Mail"
              showLabel={true}
              icon={<EmailOutlined />}
              onClick={() => handleHeaderTabClick('EMAIL')}
            /> */}
            {/* <BottomNavigationAction
              label="Wallet"
              showLabel={true}
              icon={<WalletOutlined />}
              onClick={() => handleHeaderTabClick('WALLET')}
            /> */}
            {/* <BottomNavigationAction
              label="Channels"
              showLabel={true}
              icon={<GroupOutlined />}
              onClick={() => handleHeaderTabClick('CHANNELS')}
            />
            <BottomNavigationAction
              label="Clubs"
              showLabel={true}
              icon={<SpeakerGroupOutlined />}
              onClick={() => handleHeaderTabClick('CHANNELS')}
            /> */}
            {/* <BottomNavigationAction
              label="Mini Apps"
              showLabel={true}
              icon={<AppsOutlined />}
              onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
            /> */}
            {/* <BottomNavigationAction
              label={`Notifications`}
              showLabel={true}
              icon={
                <Badge badgeContent={requestCount} color="error">
                  <Notifications />
                </Badge>
              }
              onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
            /> */}
            {/* <BottomNavigationAction
              label="Me"
              icon={
                <Avatar
                  src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    width: '30px',
                    height: '30px',
                  }}
                />
              }
              onClick={() => {
                setTabValue(4);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              selected={tabValue === 4}
            /> */}
          </Box>
        )}
        {/* Vertical Navigation */}
        {enableSideDrawer && (
          <Card
            style={{
              paddingBottom: 16,
              flexGrow: 1, // This will make the list grow to fill the available space
              overflowY: 'auto', // Allows scrolling if the chat list is too long
              height: '100vh',
              position: 'absolute',
              left: '0px',

              width: 'fit-content',
              top: '-8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column', // Align navigation items vertically
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                padding: '16px 0',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <BottomNavigationAction
                label="Dashboard"
                showLabel={true}
                icon={<HomeOutlined />}
                onClick={() => {
                  setTabValue(0);
                  if (isMobile) {
                    setShowLeft(false);
                  }
                  setSelectedChat(null);
                }}
                selected={tabValue === 0}
              />
              <BottomNavigationAction
                label="Post"
                showLabel={true}
                icon={<AddCircle sx={{ fontSize: 40 }} color="primary" />}
                onClick={() => handleHeaderTabClick('CREATEPOST')}
                selected={tabValue === 2}
              />
              <BottomNavigationAction
                label="Discover"
                showLabel={true}
                icon={<SearchOutlined />}
                onClick={() => {
                  setTabValue(1);
                  if (isMobile) {
                    setShowLeft(false);
                  }
                }}
                selected={tabValue === 1}
              />

              <BottomNavigationAction
                label="Soapbox"
                showLabel={true}
                icon={<OndemandVideoOutlined />}
                onClick={() => {
                  setTabValue(3);
                  if (isMobile) {
                    setShowLeft(false);
                  }
                }}
                selected={tabValue === 3}
              />
              <BottomNavigationAction
                label="Mega Mail"
                showLabel={true}
                icon={<EmailOutlined />}
                onClick={() => handleHeaderTabClick('EMAIL')}
              />
              <BottomNavigationAction
                label="Wallet"
                showLabel={true}
                icon={<WalletOutlined />}
                onClick={() => handleHeaderTabClick('WALLET')}
              />
              <BottomNavigationAction
                label="Channels"
                showLabel={true}
                icon={<GroupOutlined />}
                onClick={() => handleHeaderTabClick('CHANNELS')}
              />
              <BottomNavigationAction
                label="Clubs"
                showLabel={true}
                icon={<SpeakerGroupOutlined />}
                onClick={() => handleHeaderTabClick('CHANNELS')}
              />
              <BottomNavigationAction
                label="Mini Apps"
                showLabel={true}
                icon={<AppsOutlined />}
                onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
              />
              <BottomNavigationAction
                label={`Notifications`}
                showLabel={true}
                icon={
                  <Badge badgeContent={requestCount} color="error">
                    <Notifications />
                  </Badge>
                }
                onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
              />
              <BottomNavigationAction
                label="Me"
                icon={
                  <Avatar
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
                    style={{
                      backgroundColor: theme.palette.primary.light,
                      width: '30px',
                      height: '30px',
                    }}
                  />
                }
                onClick={() => {
                  setTabValue(4);
                  if (isMobile) {
                    setShowLeft(false);
                  }
                }}
                selected={tabValue === 4}
              />
            </Box>
          </Card>
        )}
      </Card>
    </Grid>
  );
}

export default LeftPanel;
